.article-wrapper {
  padding: 2rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color:rgba(0, 0, 0, 0.05)
}

.article__cover img {
  width: 100%;
  margin-bottom: 8px;
}

h1 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 4px;
}

p {
  font-size: 1.3rem;
}